import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Quality Reason" } }, [_c("select-input", { key: "facilityID", attrs: { "label": "Facility", "source": "id", "reference": "line-performance.common.local-facilities", "source-label": _vm.generateLabel, "span": 12, "value": _vm.facility.value, "disabled": true, "on-select-change": _vm.onFacilityChange } }), _c("select-input", { key: "areaID", attrs: { "label": "Department", "data-source": _vm.listAreas, "source": "id", "source-label": "name", "span": 12, "value": _vm.area.value, "default-active-first-option": "" } }), _c("text-input", { key: "code", attrs: { "form-item": "", "rules": "required", "label": "Name", "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateQualityReason",
  inject: ["crud", "facility", "area", "apiUrl", "getListAreas"],
  data() {
    return {
      facilityId: this.facility.value
    };
  },
  computed: {
    listAreas() {
      return this.getListAreas();
    }
  },
  async created() {
    this.storeValue("areaID", this.area.value);
    this.storeValue("facilityID", this.facility.value);
  },
  methods: {
    storeValue(resourceKey, value) {
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    generateLabel(facility) {
      return facility.description ? `${facility.name} ${facility.description}` : `${facility.name}`;
    },
    onFacilityChange(value) {
      this.facilityId = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateQualityReason = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-quality-reasons" }, [_c("resource", { attrs: { "name": "line-performance.common.local-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.common.local-areas", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.quanlity-reasons", "api-url": _vm.apiUrl, "redirect-route": "/line-performance/quality" } }, [_c("create-quality-reason")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateQualityReason
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
